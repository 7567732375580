import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import axios from 'axios'

import Layout from '../templates/layout'
import * as style from './index.module.scss'

import Newsletter from '../components/main-page-module/organisms/newsletter-section/newsletter'
import YourChannel from '../components/main-page-module/molecules/your-channel/your-channel'
import LandingHomeSection from '../components/main-page-module/organisms/landing-home-section/landing-home-section';
import TopFiveSection from '../components/main-page-module/organisms/top-five-section/top-five-section';
import LeaguesSection from '../components/main-page-module/organisms/leagues-section/leagues-section'
import RandomPostsSection from '../components/main-page-module/organisms/random-posts-section/random-posts-section';
import { CookiesProvider, useCookies } from 'react-cookie';
import { urlGgeneratedByLanguage } from '../utils/utils'

import CookiesBanner from '../components/main-page-module/organisms/cookies-banner/cookies_banner'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import SEO from '../components/core-module/organisms/seo/seo'

const RootIndex = ({ location }) => {
  const [cookies] = useCookies(['user_consent']);
  const [, setCookiesBanner] = useState(true)
  const siteTitle = useTranslation().t('sitename')
  const siteDescription = useTranslation().t('sitename.description')
  const siteMetaDescription = useTranslation().t('sitename.metaDescription')
  const {language } = useI18next();
  
  const [mainPosts, setMainPosts] = useState([])
  const [rightPosts, setRightPosts] = useState([])
  const [randomPosts, setRandomPost] = useState([])

  useEffect(() => {
    checkCookies(cookies)
  }, [cookies])


  useEffect(() => {       
    axios.get(`${urlGgeneratedByLanguage(language)}/api/get-only-home-articles?page=0`).then((result) => { 
    
      const resultArticlesPageZero = result.data.data
      const sectionOne = resultArticlesPageZero.slice(0, 6)
      const mainPosts = sectionOne.slice(0, 5).map((article, index) => {
          const size = (index === 0) ? 'lg' : 'md';
          return { size, ...article }
      })
      
      const rightPosts = sectionOne.slice(5, 6)
      const randomPosts = resultArticlesPageZero.slice(6, resultArticlesPageZero.length)
    
      setMainPosts(mainPosts)
      setRightPosts(rightPosts)
      setRandomPost(randomPosts)
    })

  }, [language])
  

  const checkCookies = (cookies) => {
    setCookiesBanner(Object.keys(cookies).length === 0 && cookies.constructor === Object)
  }

  useEffect(() => {       
      axios.get(`${urlGgeneratedByLanguage(language)}/api/only-homepage-articles?page=0`).then((result) => { 
      
        const resultArticlesPageZero = result.data
        const sectionOne = resultArticlesPageZero.slice(0, 6)
        const mainPosts = sectionOne.slice(0, 5).map((article, index) => {
            const size = (index === 0) ? 'lg' : 'md';
            return { size, ...article }
        })
        
        const rightPosts = sectionOne.slice(5, 6)
        const randomPosts = resultArticlesPageZero.slice(6, resultArticlesPageZero.length)
      
        setMainPosts(mainPosts)
        setRightPosts(rightPosts)
        setRandomPost(randomPosts)
      })
 

    }, [language])

  return (
    <CookiesProvider>
      <Layout {...location}>
        <div className={style.content}>
        <SEO {...{lang: language, title: siteTitle+' - '+siteDescription, description: siteMetaDescription }}/>
          <div className="wrapper">
            <LandingHomeSection mainPosts={mainPosts} rightPosts={rightPosts} />
            <TopFiveSection />
            <RandomPostsSection randomPosts={randomPosts} />
            <LeaguesSection />
            <YourChannel />
          </div>
        </div>
      </Layout>
      <CookiesBanner />
    </CookiesProvider>
  )
}

export default RootIndex

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
