import React, { useEffect, useState } from "react"
import axios from 'axios'

import * as landingSectionStyles from './landing-home-section.module.scss'
import ArticlePreview from "../../molecules/article-preview/article-preview"
import Advertisement,{classOptions} from "../../../advertisments-module/atoms/advertisement/advertisement"
import { useI18next } from 'gatsby-plugin-react-i18next';
import { loadAds, readAndGenerateTopAd, getHomeMiddleTextAd} from "../../../../services/ads-service"
import ExtraAd from "../../../advertisments-module/organisms/extra-ad/extra-ad"
import Emitter from "../../../../services/emitter"
import EgPower from "../../../advertisments-module/organisms/eg-power/eg-power"
import Fan2BeWebshop from "../../../advertisments-module/organisms/fan2be-webshop/fan2-webshop"

const LandingHomeSection = ({mainPosts, rightPosts}) => {
    const { language } = useI18next()
    const [midAdvertisement, setMidAdvertisement] = useState('')
    
    useEffect(() => {
       Emitter.on('MIDDLE_AD_EVENT', function(result){
            setMidAdvertisement(result)
       })

    }, [Emitter])
    

    //advertisments
    useEffect(() => {
        loadAds(language).then(res => {
            readAndGenerateTopAd(res.data)
            setMidAdvertisement(getHomeMiddleTextAd(res.data))
        })

    }, [language])



    return <>

        <div className={`container ${landingSectionStyles.content}`}>
            <div className={`${landingSectionStyles.firstColumn} `}>
                {
                    mainPosts?.map((article, index) => {

                        if(index === 0) {
                            return <>
                                <div key={`mainPost_${index}`} className={landingSectionStyles.firstColumn__postItem}>
                                    <ArticlePreview article={article} horizontal={index !== 0} />
                                </div>
                                <div className={landingSectionStyles.webshopFan2BeMobile}>
                                    <Fan2BeWebshop />
                                </div>
                            </>
                        }

                        return <div key={`mainPost_${index}`} className={landingSectionStyles.firstColumn__postItem}>
                        <ArticlePreview article={article} horizontal={index !== 0} />
                    </div>
                    })
                }
                <EgPower />
            </div>

            <div className={landingSectionStyles.secondColumn}>
                <div className={landingSectionStyles.secondColumn__advertisement}>
                {!!midAdvertisement && midAdvertisement.campaignId && <Advertisement campaignId={midAdvertisement.campaignId}  mediaUrl={midAdvertisement.media_link}
                        addUUID={`${midAdvertisement.id}-landing-${midAdvertisement.position}`}
                        destinationLink={midAdvertisement.url}
                        mediaType={midAdvertisement.media_type} 
                        classOption={classOptions.advertisementMid}/>}

              
                </div>
                <ExtraAd />
                {
                    rightPosts?.map((article, index) => (
                        <div key={`mainPost_${index}`} className={landingSectionStyles.firstColumn__postItem}>
                            <ArticlePreview article={article} horizontal={index !== 0} />
                        </div>
                    ))
                }
            </div>
        </div>
    </>
}

export default LandingHomeSection
