import React, { useEffect, useState } from "react"
import axios from 'axios'

import * as styles from './random-posts-section.module.scss'
import ArticlePreview from "../../molecules/article-preview/article-preview"
import Advertisement, {classOptions} from "../../../advertisments-module/atoms/advertisement/advertisement"
import { urlGgeneratedByLanguage } from "../../../../utils/utils"
import { useI18next } from 'gatsby-plugin-react-i18next';
import { getSideAd, loadAds } from "../../../../services/ads-service"
import Emitter from "../../../../services/emitter"
import LoadMoreButton from "../../../core-module/atoms/load-more-button/load-more-button"


const RandomPostsSection = ({randomPosts}) => {

    const [firstRow, setFirstRow] = useState([])
    const [otherPosts, setOtherPosts] = useState([])
    const { language } = useI18next()
    const [advertisement, setAdvertisement] = useState('')
    const [currentPosition, setCurrentPosition] =  useState(0)
    let amountOfArticlesReturned = 13
    const [totalArticlesLoaded, setTotalArticlesLoaded] =  useState(amountOfArticlesReturned)
    const [isAllArticlesLoaded, setIsAllArticlesLoaded] =  useState(false)
  
    const othersArticlesRequest = res => {
        let newTotal = totalArticlesLoaded + res.data.data.length;
        setTotalArticlesLoaded(newTotal)
        if(res.data.count <= newTotal) {
            setIsAllArticlesLoaded(true)
        }

        const allArticles = Array.isArray(res.data.data) && res.data.data.length > 0 ? res.data.data : []
        setOtherPosts([...otherPosts, ...allArticles])
    }

    const loadArticles = async () => {
        const curPosition = currentPosition + 1
        setCurrentPosition(curPosition)
        const request = axios.get(`${urlGgeneratedByLanguage(language)}/api/get-only-home-articles?page=${curPosition}`)

        const res = await request
        return othersArticlesRequest(res)
    }


    useEffect(() => {
        setFirstRow(randomPosts.slice(0, 3).map((article, index) => {
            const size = (index === 0) ? 'lg' : 'md';
            return { size, ...article }
        }))

        setOtherPosts(randomPosts.slice(3, randomPosts.length))
    }, [randomPosts])


    useEffect(() => {
        loadAds(language, 'home=1').then(res => {
            setAdvertisement(getSideAd(res.data))
        })
        Emitter.on('ADS_RANDOM_EVENT', function(result){
            setAdvertisement(result)
        })
    }, [Emitter, language])



    return (
        <div className={styles.content}>
            <div className="container">
                <div className={styles.firstRow}>
                    {
                        firstRow.map((article, index) => (
                            <div key={`randonPost_vertical${index}`} className={styles.firstRow__item}>
                                <ArticlePreview article={article} horizontal={false} />
                            </div>
                        ))
                    }
                </div>
                <div className={styles.secondRow}>
                    <div className={styles.secondRow__posts}>
                        {
                            
                            [...new Map(otherPosts.map(item => [item["id"], item])).values()].map((article, index) => (
                                <div key={`randonPost_horizontal${index}`} className={styles.secondRow__posts__item}>
                                    <ArticlePreview article={article} horizontal={true} />
                                </div>
                            ))
                        }
                        {!isAllArticlesLoaded && <LoadMoreButton click={() => loadArticles()}/>}
                    </div>
                    <div className={styles.secondRow__advertisement}>
                        {advertisement && <Advertisement mediaUrl={advertisement.media_link}
                            addUUID={`${advertisement.id}-landing-${advertisement.position}`}
                            destinationLink={advertisement.url}
                            mediaType={advertisement.media_type}
                            campaignId={advertisement.campaignId} 
                            classOption={classOptions.advertisementRandom}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RandomPostsSection
